.modalOverlay {
    width: 100%;
    height: 100vh;
    /* background-color: #0801017e; */
    /* background-color: rgba(0,0,0,.8); */
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    overflow: auto;
    bottom: 0;
    z-index: 200;
}
.modalWidth {
    border-radius: 10px;
    background: #f9f9f9f7;
    /* opacity: 0.8; */
    box-shadow: 0px 0px 85px rgb(0 0 0 / 5%);
    position: relative;
    min-height: 300px;
    max-width: 775px;
    width: 100%;
    margin: 100px auto;
    padding: 17px;
    box-sizing: border-box;
    justify-content: center;
   
}
@media (max-width: 767px) {
    .modalWidth {
        width: 90%;
        max-width: 90%;
        margin: 50px 5%;
    }
}

.title {
    font-size: 22px;
}
@media (max-width: 767px) {
    .title {
        font-size: 18px;
    }
}
.cancel {
    position: absolute; 
    right: 20px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
}
.cancel:hover {
    background-color: #1290df;
}