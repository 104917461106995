.stackcard {
    background: var(--humber-black);
    box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border:1px solid var(--humber-primary);
    padding: 15px;
  }
  .wallet {
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  @media (max-width: 991px) {
    .wallet {
      width: 45%;
    }
  }
  .wallettop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .wallettitle {
    font-weight: 600;
    color: var(--humber-light);
    font-size: 16px;
  }
  .walletbalance {
    color: var(--humber-primary);
    font-size: 2.5rem;
    font-weight: bold;
  }
  .viewleaderboard {
    color: var(--humber-link);
    font-weight: 500;
  }
  .topup {
    cursor: pointer;
  }

  .topupsuccess{
    background: #F46800;
    border: none;
    padding: .7rem;
    color: white;
    font-size: 1rem;
    width: 100%;
    max-width: 170px;
    
    border-radius:170px;
  }
  .tokenAdded{
    text-align: center;
    
    margin-top: 0px;
    margin-bottom: -15px;
    
  font-weight: 800;
font-size: 1.3rem;
line-height: 31px;




color: #123A74;
  }

  .topupimage{
    height: 57px;
    border: #121140 4px solid;
    border-radius: 51px
  }

  .text{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 1rem;
line-height: 25px;
/* or 156% */

text-align: center;

color: #1B1E1D;
  }