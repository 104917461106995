.wrap {
    width: 22%;
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 0;
    background: rgba(255, 255, 255, 0.86);
    box-shadow: 0px 0px 53px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
}
@media (max-width: 990px) {
    .wrap {
        width: 100%;
    }
}
.mybtn {
    height: 21px;
    width: 61px;
    margin: auto;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 30px;
    font-size: 10px;
    outline: none;
}


.mypointbtn {
    background-color: white;
    border: 1px solid var(--humber-button-color);
    color: var(--humber-button-color);
}
.mypointbtn:hover {
    background: var(--humber-button-color);
    color: var(--humber-light);
    /* border: 1px solid var(--humber-primary); */
}
.myrebtn {
    background: var(--humber-button-color);
    border: 2px solid var(--humber-light);
    color: var(--humber-light);
    padding-top: 3px;
    padding-bottom: 20px;
}
/* .myrebtn:hover {
    background-color: white;
    color: #F46800;
    border: 2px solid #F46800;
} */

.checkbox-form {
    background: #FFEBEE;
    color: #D32F2F;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 1px
}

.learn {
    text-decoration: none;
    color: #fff
}

.form_check{
    background:#bebebe17;
    margin-bottom: 12px;
}

.form_check_selected{
    background:#bebebe91;
    margin-bottom: 12;
}

.continue{
    border: none;
    height: 39px;
    width: 115px;
    background: #010030;
    border-radius: 12px;
    color: white;
}


.out_of_stock{
    position: absolute!important;
    background: #d2d2d2a1;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    justify-content: center;
    display: flex;
   
}

.out_of_stock_text{
    color: red;
    font-size: 19px;
    font-weight: bold;
    margin-top: 268px;
}


.reward_title{
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    color:var(--humber-button-color);
    margin-bottom: 5px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.value_wrapper{
     font-size:13px;
     flex-direction: column;
}