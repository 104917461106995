.inputContainer {
    position:relative;
    /* padding:0 0 0 20px; */
    margin:0 0 20px 0;
    /* background:#ddd; */
    /* direction: rtl; */
    /* width: 200px; */
    width: 100%;
}
/* .input {
    margin:0;
    padding-left: 45px;
   
    width: 100%;
height: 51px;

border: 1px solid #BAD1D9;
box-sizing: border-box;
} */
.input{
    margin: 0;
    padding-left: 15px;
    width: 100%;
    width: 359px;
    width: 100%;
    max-width: 380px;
    height: 51px;
    border-radius: 0;
    border: 1px solid #4e5152;
    box-sizing: border-box;
    background: transparent;
}
/* .inputImg {
    position:absolute;
    left: 15px;
    bottom:13px;
    right:5px;
    width:24px;
    height:24px;
} */
.inputImg{
    position: absolute;
    left: 121px;
    /* color: red; */
    bottom: 13px;
    right: 5px;
    width: 24px;
    height: 24px;
}
.myP {
    /* color: #C2CDD6; */
    color: #000000b8;
    padding: 20px 0;
    font-size: 15px;
   
    font-weight: bold;
}
.forgotPassword {
    color: #178AD5;
    margin-left: 185px;
    margin-top: -3px;
    font-size: 14px;
    text-transform: capitalize;
}
.myBtn {

    border-radius: 10px;
    border: none;
    color: #fff;
    width: 100%;
    padding: 15px 40px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 20px;
    cursor: pointer;
    max-width: 375px;
    background: #0775e3;
}

.input_group{
    
    max-width: 398px;
    margin: auto;
}
.input_style{
    height: 58px;
    background: none!important;
    border: 1px solid #00000063;
    padding-left: 16px;
}
.inputicon{
    font-size: 20px;
    position: absolute;
    z-index: 9;
    top: 19px;
    left: 12px;
    color: #00000099;
}

/* new styles  */

