.container {
    padding: 0;
    /* background: #419be0; */
  }
  
  .slick-slide img {
    margin: auto;
    width: 120px;
    height: 120px;
    object-fit: contain;
  }
  