.one {
        background-color: #FAFAFA;
        padding: 20px 20px 50px 20px;
        border-radius: 10px;
        width: 24%;
        min-height: 200px;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
@media (max-width: 991px) {
    .one {
        width: 100%;
    }
}