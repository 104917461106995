.newprofilewrap {
     display: flex; 
     flex-wrap: wrap; 
     min-height: 100vh; 
     width: 100%; 
     /* margin: 0px auto;  */
     gap: 6%
}
@media (max-width: 991px){
    .newprofilewrap {
        flex-direction: column;
        row-gap: 30px;
    }
}
.sectone {
width: 37%;
/* height: 500px; */
}
.secttwo {
width: 57%; 
margin-top: -29px;
/* height: 500px; */
/* border: 1px solid red */
}
@media (max-width: 991px){
    .sectone {
        width: 100%;
    }
    .secttwo {
        width: 100%;
    }
}
.bio {
    display: flex;
    flex-direction: column;
    /* background: #3D3D3D; */
    background-color: var(--humber-background);
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;
padding: 20px 20px 40px 20px;

}
.biohead {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
}
.biohead img {
    cursor: pointer;
}
.biosub {
    display: flex;
    gap: 10%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

}
.bioone {
    width: 30%;
}
@media (max-width: 450px){
    .bioone img {
        width: 70px;
        transition: 1s width ease-in;
    }
}
.biotwo {
    width: 70%;
    margin-top: 10px;
    margin-left: -25px;
    line-height: 38px;
    
}
.biotwo > div {
    color: #fff;
    display: flex;
}
.biotwo span {
    font-size: 14px;
    font-weight: bold;
}
@media (max-width: 450px){
    .biotwo span {
        font-size: 16px;
    }
}

.collect {
    display: flex;
    gap: 4%;
margin-top: 20px;
}
.collectone {
    width: 48%;
}
.transactions {
    margin-top: 30px;
        background: var(--humber-light);
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;
padding: 20px;
text-align: center;
}
.avatar {
    height: 1.5em;
    width:  1.5em;
    border-radius: 50%;
    margin: 0 8px;
    background: var(--humber-light);
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    text-align: center;
    text-transform: capitalize;
    
    
}

.avlist {
    background: #FFFFFF;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;
padding: 20px;
display: flex;
margin-top: 10px;
}
@media (max-width: 991px) {
    .avlist {
        flex-direction: column;
    }
}
.guessandtrivia {
    margin-top: 30px;
/* padding: 20px; */
display: flex;
gap: 4%;
}
@media (max-width: 991px) {
    .guessandtrivia {
        flex-direction: column;
        row-gap: 30px;
    }
}
.gametrans {
    width: 48%;
    background: #FFFFFF;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;
padding: 20px;
}
@media (max-width: 991px) {
    .gametrans {
        width: 100%;
    }
}
.redeem {
    margin-top: 50px;
    text-align: center;
    margin-top: 50px;
    text-align: center;
    border: 1px solid #d3d3d329;
    background: #fff;
    box-shadow: 0 0 14px #0000001c;
    border-radius: 10px;
    padding: 20px 0;

}
.rdbox {
    width: 60%;
    margin: 0 auto;
    position: relative;
    /* border: 1px solid red; */
}
.rdbox div{
    /* color:var(--humber-light) */
}
.rdbox div strong{
    color:var(--humber-primary)
} 
.guesscolor {
    color: #06B4C7;
}
.triviacolor {
    color: var(--humber-primary);
}
.title {
    font-size: 26px;
    font-weight: bold;
    padding-bottom: 15px;
}
.guessbg {
    background-image: url('../../assets/svg/question-guess.svg');
    background-repeat: no-repeat;
    background-position: 154% 125px;
    background-size: 75%;
  
    background-color: var(--humber-dark);
    box-shadow:var(--box-shadow);
}
.triviabg {
    background-image: url('../../assets/svg/trivia-qna.svg');
    background-repeat: no-repeat;
    background-position: 29% 150px;
    background-size: contain;
  
    background-color: var(--humber-dark);
    box-shadow:var(--box-shadow);
}
.scramble {
    background-image: url('../../assets/scrabble-qn.png');
    background-repeat: no-repeat;
    background-position: 36% 126px;
    background-size: cover;
    background-color: var(--humber-dark);
    box-shadow:var(--box-shadow);
}

.editwrapper {
    position: absolute; 
    background: rgba(255, 255, 255, 0.8); 
    width: 100%;
    min-height: 100%;
    margin-top: -100px;
}

.history_total{
    height: 20px!important;
    /* top: 0px; */
    bottom: 31px!important;
    border-radius: 43px!important;
    width: 18px!important;
    height: 18px!important;
    color: white!important;
    padding: 2px 0 3px 4px!important;
}

.first::after{
content: '';
  height: 100%; 
  width: 1px;

  position: absolute;
  right: 0;
  top: 0; 

  background-color: #000000; 
}



.link_history:hover{
    background:none!important
}

.slick-next:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #000 !important;
    font-size: 1.3rem;
}



    .slick-prev{
        font-size: 20px;
        line-height: 1;
        opacity: .75;
        color: #000 !important;
        background-color: black;
    }


.add_account_btn{
    margin-top: 15px;
    margin-bottom: -13px;
    margin-top: 15px;
    margin-bottom: -13px;
    background: var(--humber-button-color);
    border: none;
    /* padding: 7px; */
    height: 30px;
    font-size: 11px;
    color: var(--humber-light);
    border-radius: 5px;
    padding:0 13px;
}

.add_account_btn_wrapper{
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 768px) {
    .add_account_btn_wrapper{
        /* display:flex;
        justify-content: center;
        align-items: center; */
    } 
}

@media (max-width: 559px){
    .accountName{
        width: 88px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.comfirm_del_btn{
    background: #df3131;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    border: none;
    color: var(--humber-light);
    font-weight: 400;
    font-size: 16px;
    margin-top: 1rem;
    max-width: 126px;

}

.comfirm_del{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px;
}

.comfirm_wrapper{
    margin-bottom: 7px;
    margin-top: -22px;
}

