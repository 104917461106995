.inputContainer {
    position:relative;
    /* padding:0 0 0 20px; */
    margin:0 0 20px 0;
  
    /* direction: rtl; */
    /* width: 200px; */
    width: 100%;
}
/* .input {
    margin:0;
    padding-left: 45px;
    
    width: 378px;
    width: 100%;
height: 51px;

border: 1px solid #BAD1D9;
box-sizing: border-box;
} */

.input{
    margin: 0;
    padding-left: 45px;
    /* width: 100%; */
    width: 378px;
    max-width: 393px;
    width: 100%;
    background: transparent;
    height: 51px;
    border-radius: 0;
    border: 1px solid #292c2d;
    box-sizing: border-box;
}
.inputImg {
    position:absolute;
    left: 111px;
    bottom:13px;
    right:5px;
    width:24px;
    
    height:24px;
}
.myBtn {
    border-radius: 10px;
    border: none;
    color: #fff;
    width: 100%;
    padding: 15px 40px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 20px;
    cursor: pointer;
    max-width: 375px;
    background: #0775e3;
}
.myP {
    /* color: #C2CDD6; */
    color: #000000a3;
    padding: 6px 0;
    font-size: 20px;
    
    font-weight: bold;
}



.input_group{
    
    max-width: 398px;
    margin: auto;
}
.input_style{
    height: 58px;
    background: none!important;
    border: 1px solid #00000063;
    padding-left: 16px;
}
.inputicon{
    font-size: 27px;
    position: absolute;
    z-index: 9;
    top: 15px;
    left: 5px;
}