.table{
    background: var(--humber-dark) !important;
    border-radius: 0 0 20px 20px;
    padding: 0px 0px 41px 1px;

    /* border-collapse: separate; */
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: #B9B9B9;
}

.table>:not(caption)>*>* {
    padding: .5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    padding-left: 41px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    /* color:var(--humber-light) */
}





