.list_group{
    display: flex;
    flex-direction: column;
    padding: 0;
    line-height: 5;
    width: 256px;
    margin: auto;
    border-radius: .25rem;
    padding-left: 16px
}

.list_group_item{
    position: relative;
    display: block;
    color: #ffffff;
    text-decoration: none;
    background-color: #fff;
    background: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16.1972px;
    line-height: 95px;
    color: #FFFFFF;
    gap: 26px!important;
   
}



.list_group_item img{
    height: 28px;
}

.list_group_sub{
    margin-left: -35px;

}
.list_group_item_sub{
    position: relative;
    display: block;
    padding: 0.5rem 2rem;
    color: white;
    line-height: 43px;
    text-decoration: none;
    background-color: #3C3C3C;
    border: 1px solid rgba(0,0,0,.125);
}



.sidenav {
    height: 100%;
    width: 0px;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    
  }
  
  .sidenav a {
    padding: 0px 8px 8px 32px;
    text-decoration: none;
    font-size: 56px;
    color: white;
    display: block;
    transition: 0.3s
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .burger{
    top: 25px;
    z-index: 68;
    right: 30px;
    
    
  }
  @media (min-width:1221px){
    .burger{
       display:none
        
      }
  }

  
  .burger div{
    width: 46px;
    height: 7px;
    background-color: white;
    margin: 6px 0;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
  

