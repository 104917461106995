.btn {
   
        background-color: #2B9FDD;
        border: none;
        outline: none;
        padding: 10px 50px;
        cursor: pointer;
        color: #fff;
        font-weight: 500;
        text-transform: capitalize;
        border-radius: 5px;
        font-size: 12px;
    
    
}
.inputContainer {
    position:relative;
    margin:0 0 20px 0;
    
    /* direction: rtl; */
    width: 330px;
}
/* .input {
    margin:0;
    padding: 10px 20px;
    outline: none;
    width: 100%;
height: 51px;

border: 1px solid #BAD1D9;
box-sizing: border-box;
} */

.input{
    margin: 0;
    padding-left: 11px;
    width: 100%;
    width: 339px;
    width: 75%;
    height: 51px;
    /* max-width: 100%; */
    /* background: #00000008; */
    border-radius: 5px;
    border: 1px solid #3033335c!important;
    /* box-sizing: border-box; */
    border: none;
}