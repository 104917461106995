.btn {
    background-color: #2B9FDD;
    border: none;
    outline: none;
    padding: 10px 50px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 30px;
    font-size: 12px;
    border-radius: 6px;
}
.inputContainer {
    position: relative;
    margin: 0 0 20px 0;
    /* background: #ddd; */
    /* direction: rtl; */
    width: 100%;
    border-radius: 0px;
    
}
.input {
    margin: 0;
    padding: 10px 20px;
    outline: none;
    width: 100%;
    height: 51px;
    border: 1px solid #BAD1D9;
    box-sizing: border-box;
    border-radius: 12px;
}

.reset_otp{
    background-color: #80808091;
    border: none;
    width: 39%;
    outline: none;
    /* padding: 10px 47px; */
    cursor: pointer;
    color: #fff;
    /* font-weight: bold; */
    text-transform: capitalize;
    border-radius: 30px;
    /* font-size: 12px; */
    border-radius: 6px;
    padding: 8px 0;
}