.myBtn {
    background: linear-gradient(266.9deg, #0CC3F3 38.58%, #2B9FDD 85.02%);
border-radius: 50px;
border: none;
color: #fff;
width: 100%;
padding: 15px 40px;
font-weight: bold;
text-transform: capitalize;
margin-bottom: 20px;
cursor: pointer;
}
.myRBtn {
    background: linear-gradient(266.9deg, #0CC3F3 38.58%, #2B9FDD 85.02%);
border-radius: 50px;
border: none;
color: #fff;
width: 100%;
padding: 15px 10px;
font-weight: bold;
text-transform: capitalize;
margin-bottom: 20px;
cursor: pointer;
}
.inputContainer {
    position:relative;
    /* padding:0 0 0 20px; */
    margin:0 0 20px 0;
    background:#ddd;
    /* direction: rtl; */
    /* width: 200px; */
    width: 100%;
}
.input {
    margin:0;
    padding-left: 45px;
    /* width: 100%; */
    /* width: 378px; */
    width: 100%;
height: 51px;

border: 1px solid #BAD1D9;
box-sizing: border-box;
}
.inputImg {
    position:absolute;
    left: 15px;
    bottom:13px;
    right:5px;
    width:24px;
    height:24px;
}