.upperball {
    position: absolute;
    display: flex; 
    text-align: center;
    justify-content: center; 
    align-items: center; 
    top: 0;
    left: 40px; 
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #123A74;
    font-size: 8px; 
    color: white;
  }
  .upperballone {
    position: absolute;
    display: flex; 
    text-align: center;
    justify-content: center; 
    align-items: center; 
    top: -10px;
    left: 92%;
    border: 2px solid #FFFFFF;
    right: auto; 
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #123A74;
    font-size: 8px; 
    color: white;
    font-size: 7px;
  }
  .eachWrap {
    display: flex;
    column-gap: 1%;
    row-gap: 25px;
    flex-wrap: wrap;
    box-sizing: border-box
}
@media (max-width: 991px) {
    .eachWrap {
        flex-direction: column;
        margin-bottom: 20px;
    }
}
.yesterday {
    color: #123873; font-size: 22px; font-weight: bold
}
.seeprizes {
    color: #0A7815; font-size: 16px
}
@media (max-width: 991px) {
    .yesterday {
        font-size: 14px;
    }
    .seeprizes {
        font-size: 14px;
    }
}