.categorywrapper {
    display: flex;
    flex-direction: column;
    padding: 0 0 30px 0;
}
.top {
    width: 100%; 
    height: 100%;
    text-align: center;
    padding: 5px 0;
}
.title {
    color: #09070A;
    font-size: 32px;
    font-weight: bold;
}
.cardwrapper {
    display: flex; 
    column-gap: 5%;
    row-gap: 20px;
    width: 100%; 
    /* height: 400px; */
    margin-top: 20px;
    flex-wrap: wrap;
}
@media (max-width: 991px){
    .cardwrapper {
        row-gap: 30px;
        height: 400px;
        overflow: scroll;
    }
}
.card {
    background: #FFFFFF;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;
width: 30%;
height: 180px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0 20px;
text-align: center;
color: #444444;
cursor: pointer;
}
@media (max-width: 991px){
    .card {
        width: 100%;
    }
}
.cardtext {
    padding: 15px 0;
}
.activecard {
    font-weight: bold;
    background-color: #123873;
    color: #fff;
}