.top {
    text-align: center;
    margin-top: -20px;
}
.top img {
    width: 120px;
    height: 120px;
    margin-top: -20px;
}
.title {
    color: #133873;
    font-size: 36px;
    font-weight: bold;
    padding: 0;
    margin-top: -40px;
}
.description {
    font-size: 24px;
    color: #16407A;
    font-weight: bold;
}
.readme {
    color: #444444;
    font-size: 14px;
    padding: 0 0 40px 0;
}
.btn {
    background-color: #2B9FDD;
    border: none;
    outline: none;
    padding: 10px 50px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 30px;
    font-size: 18px;
}
.cardwrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;
}
@media (max-width: 991px) {
    .cardwrapper {
        flex-direction: column;
        row-gap: 30px;
    }
}
.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(107, 104, 104);
    border-radius: 10px;
    width: 30%;
    min-height: 290px;
    padding: 0 0 20px 0;
}
@media (max-width: 991px) {
    .card {
        width: 100%;
    }
}
.cardtop {
    width: 80%;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
}
@media (max-width: 991px) {
    .cardtop {
    justify-content: space-between;
        align-items: center;
    }
}
.name {
    display: flex;
    flex-direction: column;
    padding: 0 5px 0 10px;
    /* margin-left: 10px; */
    font-size: 14px;
    font-weight: bold;
    color: white;
}
@media (max-width: 991px) {
    .name {
        font-size: 18px;
    }
}
.contestant {
    font-size: 12px;
}
@media (max-width: 991px) {
    .contestant {
        font-size: 14px;
    }
}
.card ul {
    margin: 0 0 0 0;
    padding: 0;
    width: 80%;
}
.card ul li {
    list-style-type: none;
    color: white;
    padding: 3px 10px;
    font-size: 12px;
    text-transform: capitalize;
}
.plus {
    cursor: pointer;
}
