.select_option{
   
    
   
    
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .4rem;
}

.select_option:focus{
    outline:none!important;
    border:1px solid #123873;
}

.h3{
    font-size: 1rem;
    color: #123873;
    margin-bottom: 1rem;
}