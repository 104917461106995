.statscardwrapper {
  display: flex;
  flex-direction: column;
}
.my-pts {
  flex-direction: row;
  background: #ffffff;
  box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 10px;
  /* padding: 20px; */
}
.pos-wallet {
  display: flex;
  /* min-width: 450px; */
  justify-content: space-between;
  margin-bottom: 10px;
  /* gap: 50px; */
}
.max-w {
  width: 100%;
}
.max-m {
  width: 260px;
}
.my-points-circle {
  border-right-color: #f5dd64;
  border-top-color: #2755b3;
  border-bottom-color: #f5dd64;
  border-left-color: #2755b3;
  border-width: 8px;
  border-style: solid;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* border: 5px solid green; */
}
.my-pts-head {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}
.not-display {
  display: none;
}
.holder-leaderboard {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto 15px auto;
  width: 70%;
  text-align: center;
}
.no-one-col {
  background: #FFFFFF; width: 50%; box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05); border-radius: 10px; padding: 20px;
}