.table{
    
    border-radius: 20px;
    padding: 20px 20px 20px 20px;
   
    /* border: 1px solid var(--humber-primary); */
    margin-top: 13px!important;
    background: var(--humber-dark);
}
.game_history{
    background:none!important;
    /* color:var(--humber-light) */
}

.game_history tbody{
    opacity: 0.8
}

.table>:not(:last-child)>:last-child>* {
    /* border-bottom-color: #B9B9B9; */
}

