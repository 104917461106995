.stackcard {
    background: #FFFFFF;
    box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    /* justify-content:center; */
    align-items:center;
    border:1px solid var(--humber-light)
  }
.mypointscircle {
    border-right-color: #f5dd64;
    border-top-color: #2755b3;
    border-bottom-color: #f5dd64;
    border-left-color: #2755b3;
    border-width: 8px;
    border-style: solid;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: 20px;
    font-weight: 600;
    /* border: 5px solid green; */
  }