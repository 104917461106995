.contii {
    background: #FFFFFF;
    width: 85%;
    margin: 50px auto;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;
padding: 30px;
}

.bgg {
    /* background: linear-gradient(90deg, #123A74 8.8%, #4CA9E3 100%); */
    /* background: linear-gradient(90deg, #11151B 8.8%, #6B6B6B 43.9%, #A6A6A6 57.69%, #2E3034 100%); */ 
    /* background: #023062; */
    background: linear-gradient(90deg, #010030 21.67%, #29268C 57.01%, #010030 84.45%);
}
.wrapp {
    background: #FFFFFF;
    border-radius: 20px;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
width: 85%; margin: 50px auto;
}
.title {
    font-size: 25px;
}
.myp {
    font-size: 16px; padding-top: 10px; line-height: 1.5em
}
.container{
    background:#ffffff;
    background: #fff;
    width: 85%;
    margin: 50px auto;
    box-shadow: 0 0 85px rgb(0 0 0 / 5%);
    border-radius: 10px;
    padding: 30px;
}