.myposition {
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  .stackcard {
    background: #ffffff;
    box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 15px;
  }
  .pointstitle {
    font-weight: 600;
    color: #000;
    font-size: 16px;
    padding: 0 0 10px 0;
  }
  .positionnumber {
    font-size: 50px;
    color: #123873;
    font-weight: bold;
  }
  .positionnumber img {
    margin: -20px 0 0 10px;
  }
  .viewleaderboard {
    color: #000;
    font-weight: 500;
  }
  @media (max-width: 600px){
    .viewleaderboard {
      font-size: 10px;
    }
  }

  .suffix {
    font-size: 16px;
  }

    
 
  @media (max-width: 991px) {
    .myposition {
      width: 45%;
    }
 
  }