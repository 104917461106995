.landingWrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  /* position: relative; */
  position: absolute;
  background-position: center;
  background-size: cover;
  /* background: linear-gradient(to-right, #1290DF, #2C6BA5, #2C6BA5); */
  /* background: linear-gradient(to right, #1290df, #2c6ba5, #123873); */
  /* background-image: url(../../assets/images/allstars.png) no-repeat center center; */
  /* background: url(../../assets/svg/allavatars.png) no-repeat #11151B; */
  /* transform: rotate(-0.5deg); */
  background: url(../../assets/images/allstars1.png) no-repeat center 80%, linear-gradient(90deg, #11151B 8.8%, #6B6B6B 43.9%, #A6A6A6 57.69%, #2E3034 100%);
  /* background: url(../../assets/svg/allavatars.svg) no-repeat 100%, linear-gradient(90deg, #11151B 8.8%, #6B6B6B 43.9%, #A6A6A6 57.69%, #2E3034 100%); */
}

.gift_rapper{
     /* height: 302px; */
     width: 100%;
     /* background-repeat: no-repeat; */
     position: 67%;
     /* bottom: 58px; */
     /* bottom: 30px; */
     /* width: 100%; */
     /* max-width: 159px; */
     /* height: 484px; */
     height: 20%;
    
     background-size: cover;
     background-repeat: no-repeat!important;
     z-index: 99;
     top: 80%;
  
}

@media (max-width: 991px){
    .gift_rapper{
        display: none;
    }
}



.customeCard{
    width: 100%;
    max-width: 293px;
    padding: 25px 0 25px 0;
    max-height: 577px;
    border-radius: 18px;
    height: 20%x;
    transition: all 0.3s ease-in-out;
    
    
}

.customeCard:hover{
    max-width: 439px;

}
/* .customeCard div >  .cardImage{
    height:20px!important
} */




@media (max-width: 991px) {
    .landingWrapper{
        height: auto;
        
    }
   
}




.content {
    margin: 30px 0 0 87px; position: relative
}
.btns {
    display: flex; 
    margin-top: 40px; 
    justify-content: space-between; 
    width: 350px
}
@media (max-width: 767px) {
    .content {
        margin: 5px 0 0 20px;
    }
    .btns {
        max-width: 90%;
        flex-direction: column;
    }
    .button {
        padding: 12px 0;
    }
    .btns > button {
        padding: 11px 0;
    }
    .btns > button:nth-child(2) {
        margin-top: 20px
    }
}
.header {
    width: 70%;
    margin: 0px auto;
    color: #fff;
    text-align: center;
    padding: 50px 0 0 0;
}
.header .h1 {
    color: #fff;
    padding: 0 100px;
    font-size: 33px;
    margin-top: -0.9999em;
    line-height: 1.1em;
    
}
.header p {
    font-size: 18px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
    padding-top: 10px;
}
@media (max-width: 991px) {
    .header {
        width: 90%;
        /* margin: 30px auto; */
        padding: 60px 0 0 0;
    }
    .header .h1 {
        font-size: 18px;
        padding: 0 10px;
    }
    .header p {
        font-size: 14px;
    }
}
/* .imgicon {
    display: block;
} */
@media (max-width: 991px){
    .imgicon {
        display: none;
    }
}
.cards {
    width: 57%;
    justify-content: center;

    margin: -10px auto;
    display: flex;
    align-items: center;
    grid-gap: 1%;
    gap: 3%;
    position: relative;
    z-index: 10;
}
.cards div {
    min-width: 220px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background: #F4F1F1;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;
padding: 20px;
transition:all .7s ease-in-out; 
}
.cards > div:nth-child(1) {
    height: 239px;
}
.cards div:nth-child(2) {
    /* min-height: 200px; */
    height: 239px;
}
.cards div:nth-child(3) {
    height: 239px;
}
.cards  :hover{
    width: 60%;
    transform: scale(1.1);
}
@media (max-width: 991px) {
    .cards {
        flex-direction: column;
        width: 90%;
        row-gap: 35px;
        transition:all 1s ease-in-out; 
    }
    .cards div {
        width: 100%;
        display: flex;
        transition:all 1s ease-in-out; 
    }
    .cards div:nth-child(1) {
        width: 70%;
        transition:all 1s ease-in-out; 
        
        /* height: 100px; */
    }
    .cards div:nth-child(2) {
        width: 85%;
        transition:all 1s ease-in-out; 
        /* max-height: 100px!important; */
    }
    .cards div:nth-child(2) img {
        height: 50px;
        transition:all 1s ease-in-out; 
    }
    .cards div:nth-child(3) {
        width: 70%;
        /* height: 100px; */
    }
}
.gifts {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
        width: 50%;
        /* height: 100px; */
        position: absolute;
        left: 25%;
        right: 25%;
        bottom: 0;
}
@media (max-width: 991px) {
    .gifts {
        display: none;
    }
}

/* mobile for cards */

@media(max-width: 991px){
    .mobileCard{
        flex-direction: column;
    }
}
.gift_images:hover{
    transform: scale(1.1);
}

.giftMicroVave{
    left: 1rem;
    bottom: 1rem;
    top: 3rem;
}

.iphone{
    left: 45%;
    top: -5rem;
}

.cash{
    top: -3rem;
    left: 23%;

}

.camry{
    right:17px
}
.generator{
    top: 0; 
    right: 12rem;
    margin: auto;
    /* left: 50%; */
    top: -3rem;
}
.gifts img {
    width: 120px;
    transition: transform .2s;
    cursor: pointer;
}
.gifts img:hover {
    transform: scale(1.5);
    /* margin-bottom: 20px; */
}
.gifts img:nth-child(1) {
    margin-bottom: 15px;
}
.gifts img:nth-child(2) {
    margin-top: -150px;
}
.gifts img:nth-child(3) {
    margin-top: -250px;
}
.gifts img:nth-child(4) {
    margin-top: -150px;
}
.gifts img:nth-child(5) {
    margin-bottom: 15px;
}
.halfshape {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    z-index: 1;
}
@media (max-width: 991px) {
    .halfshape {
        display: none;
    }
}
.rolltext {
    
        text-align: center;
        position: absolute;
        z-index: 2;
        left: auto;
        right: auto;
        color: black;
        font-size: 25px;
        font-weight: 500;
        width: 43%;
        margin: 0 28%;
        bottom: 30px;
        font-style: italic;
        font-weight: 800;
        line-height: 1;
        top: 44px;
        bottom: 15px;
        text-transform: uppercase;
        transform: rotate( 
    -2deg
     );

}
@media (max-width: 991px) {
    .rolltext {
        display: none;
    }
}
.btn {
    background-color: #2B9FDD;
    border: none;
    outline: none;
    padding: 10px 30px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    font-size: 14px;
}
.btn:disabled {
    cursor: not-allowed;
    opacity: 0.8
}
.bottomblack {
    width: 100%; 
    height: 68px; 
    background-color: black; 
        position: absolute;
        /* top: 100%;  */
        bottom: 0;
        /* z-index: -10; */
        /* position: absolute; */
}

@media (max-width: 991px){
    .bottomblack{
        height:0!important
    }
}


.wrapper{
    gap: 20px;
    flex-direction: column!important;
    background:red
    }