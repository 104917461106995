.container {
  position: relative;
  width: 100%;
  text-align: center;
}
.title {
  padding: 5px 0 20px 0;
}
.prodWrap {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  padding: 30px;
}
.oneCard {
    background: #FFFFFF;
/* box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05); */
border-radius: 10px;
width: 45%;

box-shadow: 0.6rem 0.7rem 0.5rem rgb(0 0 0 / 20%);
padding: 25px;
cursor: pointer;
font-size: 14px;
font-weight: 500;
}
.oneCard:hover {
    background-image: linear-gradient( #a7c8dd, #2C6BA5  );
}

.clicked {
    background-image: linear-gradient( #a7c8dd, #2C6BA5  );
}