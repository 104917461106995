b {
    color: #0366A5;
}
p {
    color: #747474;
    font-size: 13px;
}
.panelling {
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 100px;
    color: red;
    background-color: blue;
}
.bgg {
    background: linear-gradient(90deg, #123A74 8.8%, #4CA9E3 100%);
}
.wrapp {
    /* background: #FFFFFF; */
    border-radius: 20px;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
width: 85%; margin: 50px auto;
}

.contii {
    /* background: #FFFFFF; */
    width: 85%;
    margin: 50px auto;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
border-radius: 10px;
padding: 30px;
}

.bgg {
    background: linear-gradient(90deg, #123A74 8.8%, #4CA9E3 100%);
}
.wrapp {
    /* background: #FFFFFF; */
    border-radius: 20px;
box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.05);
width: 85%; margin: 50px auto;
}
.title {
    font-size: 25px;
    color:var(--humber-primary)
}
.myp {
    font-size: 16px; padding-top: 1px; line-height: 1.5em;
}